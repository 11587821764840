import React from "react";
import styled from "styled-components";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import OneImage from "../assets/image/png/1.png";
import TwoImage from "../assets/image/png/2.png";
import ThreeImage from "../assets/image/png/3.png";
import ScanEverything from '../assets/image/png/ScanEverything.png'
import SelectiveScanning from '../assets/image/png/SelectiveScanning.png'

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box, Span } from "../components/Core";
import Helmet from "react-helmet"

const NavStyled = styled(Nav)`
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #f7f7fb;
  padding-top: 15px;
  padding-bottom: 15px;
  a {
    color: ${({ theme }) => theme.colors.dark} !important;
    &:hover,
    &:active,
    &:visited {
      color: ${({ theme }) => theme.colors.secondary} !important;
    }
  }
`;

const Faq = () => {
  return (
    <>
      <Helmet>
        <title>HotSnail - How Mail Scanning Working</title>
        <meta name="title" content="HotSnail - Mail Scanning & Forwarding FAQs"></meta>
        <meta name="description" content="HotSnail - Fast Answered Questions regarding HotSnail mail scanning, mail forward and mail redirection" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://hotsnail.com.au/" />
        <meta property="og:title" content="HotSnail - Mail Scanning & Forwarding FAQs" />
        <meta property="og:description" content="HotSnail - Fast Answered Questions regarding HotSnail mail scanning, mail forward and mail redirection" />
        <meta property="og:image" content="https://www.hotsnail.com.au/hotsnail/img/HotSnailLogo2.png" />

      </Helmet>
      <PageWrapper footerDark>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="12">
                <Title variant="hero">How it works</Title>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="pb-5">
          <Container>
            <div class="container">

              <div class="row">
                <div class="col-12 col-sm-6 col-md-4 aos-init aos-animate" data-aos="fade-up">
                  <div class="benifits-item text-center p-3">
                    <div class="feature-icon">
                      <img src={OneImage} alt="Signup - Mail Redirection -Mail Forwarding Services - Hotsnail"
                        style={{ marginBottom: "10px", height: "80px" }} />
                    </div>
                    <div class="benifits-text">
                      <h3 class="mb-2">Sign up online &amp; get your new address</h3>
                      <p>After registering we will send you your unique user name and address.</p>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 aos-init aos-animate" data-aos="fade-up">
                  <div class="benifits-item text-center p-3">
                    <div class="feature-icon">
                      <img src={TwoImage} alt="Update - Mail Redirection -Mail Forwarding Services - Hotsnail"
                        style={{ marginBottom: "10px", height: "80px" }} />
                    </div>
                    <div class="benifits-text"><h3 class="mb-2">Update your postal address</h3>
                      <p>Order from Australian shops or redirect your mail with Australia post or update all your postal information with your new address.</p>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 aos-init aos-animate" data-aos="fade-up">
                  <div class="benifits-item text-center p-3">
                    <div class="feature-icon">
                      <img src={ThreeImage} alt="Mange - Mail Redirection -Mail Forwarding Services - Hotsnail"
                        style={{ marginBottom: "10px", height: "80px" }} />
                    </div>
                    <div class="benifits-text">
                      <h3 class="mb-2">Manage your mail online</h3>
                      <p>Manage your mail with our state of the art online management system. Forward your letters or parcels worldwide</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </Container>
        </Section>
        <Container>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="12">
                <Title>Automatic Options</Title>
                <p>HotSnail can process your mail the way you want</p>

                <div class="row">
                  <div class="col-12 col-sm-6 aos-init aos-animate" data-aos="fade-up">
                    <div class="benifits-item text-center p-3">
                      <div class="feature-icon">
                        <img src={ScanEverything}
                          alt="Scan - Mail Redirection -Mail Forwarding Services - Hotsnail"
                          style={{ marginBottom: "20px", height: "40px" }} />
                      </div>
                      <div class="benifits-text">
                        <h3 class="mb-2">Scan Everything</h3>
                        <p>We will open your mail and scan all scanable documents into PDF files and then upload them to HotSnail’s secure website. Once on the website you can read, sort, store, shred or forward them using our Mail Redirection Services.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 aos-init aos-animate" data-aos="fade-up">
                    <div class="benifits-item text-center p-3">
                      <div class="feature-icon">
                        <img src={SelectiveScanning}
                          alt="Selective scanning - Mail Redirection -Mail Forwarding Services - Hotsnail "
                          style={{ marginBbottom: "20px", height: "40px;" }} />
                      </div>
                      <div class="benifits-text">
                        <h3 class="mb-2">Selective Scanning</h3>
                        <p>Choose this plan if you want to choose which mail items are opened. We will just scan the envelope for you, based on an image of the envelope, you can decide if you want us to open and scan or forward the item via our Online Mail Management Services website.</p>
                      </div>
                    </div>
                  </div>
                </div>

              </Col>
            </Row>
          </Container>

        </Container>

      </PageWrapper>
    </>
  );
};
export default Faq;
